<template>
  <div class="candidates">
    <div>
      <van-tabs :v-model="listfroms.stage" color="#2fa2f4" @click="onClick">
        <van-tab
          v-for="item in StageList"
          :key="item"
          :title="item.Name"
          :name="item.Stage"
        >
          <div v-for="item in namelist" :key="item" class="box">
            <div style="padding: 10px 15px">
              <div @click="xiangqing = true">
                <div style="display: flex; justify-content: space-between">
                  <span>{{ item.Name }}</span>
                  <span style="color: red">投递职位:{{ item.JTitle }}</span>
                </div>
                <div style="font-size: 15px; margin: 5px 0px">
                  <span>联系方式:{{ item.Mobile }}</span>
                </div>
                <div style="margin: 5px 0px">
                  <span style="font-size: 13px"
                    >加入候选人时间:{{ item.AddCallListTime }}</span
                  >
                </div>
                <!-- <div style="margin-bottom: 8px;">
                <span>状态: <span style="color:red;margin-bottom:8px">{{item.RecruitStageDesc}}</span></span>
              </div> -->
              </div>
              <div>
                <van-button
                  type="info"
                  size="small"
                  style="margin: 3px 8px"
                  v-if="listfroms.stage == 1"
                  @click="addRCAdviser(item.RPId)"
                  >面试</van-button
                >
                <!-- <van-button
                  type="warning"
                  size="small"
                  v-if="listfroms.stage == 1"
                  @click="RemoveCallList(item.RPId)"
                  >不合格</van-button
                > -->
                <van-button
                  type="primary"
                  size="small"
                  v-if="listfroms.stage == 4"
                  @click="Addmission(item.RPId)"
                  style="margin: 3px 8px"
                  >录用</van-button
                >
                <van-button
                  type="primary"
                  size="small"
                  v-if="listfroms.stage == 5"
                  @click="AddEntry(item.RPId)"
                  style="margin: 3px 8px"
                  >入职</van-button
                >
                <van-button
                  type="info"
                  size="small"
                  v-if="listfroms.stage == 6"
                  @click="DelayEntrys(item.RPId)"
                  style="margin: 3px 8px"
                  >延期入职</van-button
                >
                <van-button
                  type="danger"
                  size="small"
                  @click="dleEliminate(item.RPId)"
                  v-if="listfroms.stage != 9"
                  style="margin: 3px 8px"
                  >不合适</van-button
                >
              </div>
            </div>
            <van-popup
              v-model="xiangqing"
              position="right"
              get-container="body"
              :style="{ height: '100%', width: '90%' }"
            >
              <van-cell-group>
                <van-cell title="姓名" :value="item.Name" />
                <van-cell title="性别" :value="item.SexDesc" />
                <van-cell title="手机号" :value="item.Mobile" />
                <van-cell title="职位" :value="item.JTitle" />
                <van-cell
                  title="加入候选人时间"
                  :value="item.AddCallListTime"
                />
                <van-cell title="加入人" :value="item.AddCallListMan" />
              </van-cell-group>
              <van-cell-group v-if="listfroms.stage >= 4">
                <van-cell title="面试时间" :value="item.InterviewTime" />
                <van-cell title="面试官" :value="item.Interviewer" />
                <van-cell title="面试地点" :value="item.InterviewPlace" />
                <van-cell
                  title="面试结果"
                  :value="item.InterviewFBResultDesc"
                />
                <van-cell
                  title="淘汰或放弃原因"
                  :value="item.InterviewFailedReasonDesc"
                />
              </van-cell-group>
              <van-cell-group v-if="listfroms.stage >= 5">
                <van-cell title="月薪" :value="item.MonthSalary" />
                <van-cell title="年薪" :value="item.YearSalary" />
                <van-cell title="Offer签订时间" :value="item.OfferDate" />
                <van-cell title="通知入职日期" :value="item.NoticeEntryTime" />
                <van-cell title="预计入职日期" :value="item.PlanInDate" />
                <van-cell title="通知入职人" :value="item.NoticeOfferMan" />
                <van-cell title="入职日期" :value="item.InDate" />
              </van-cell-group>
              <van-cell-group v-if="listfroms.stage >= 6">
                <van-cell title="试用日期" :value="item.TryDate" />
                <van-cell title="过保日期" :value="item.OverDate" />
                <van-cell title="入职备注" :value="item.EntryRemark" />
              </van-cell-group>
              <van-cell-group v-if="listfroms.stage == 9">
                <van-cell title="淘汰时间" :value="item.EliTime" />
                <van-cell title="淘汰人" :value="item.EliMan" />
                <van-cell title="淘汰原因" :value="item.EliReason" />
                <van-cell title="淘汰说明" :value="item.EliRemark" />
              </van-cell-group>
            </van-popup>
          </div>
        </van-tab>
      </van-tabs>
      <!-- 面试 -->
      <van-popup
        v-model="fromindexA"
        round
        position="bottom"
        get-container="body"
        :style="{ height: '45%' }"
      >
        <div>
          <van-field
            v-model="mianshi.InterviewTime"
            label="面试时间"
            @click="InterviewTime = true"
            readonly="readonly"
            placeholder="请选择面试时间"
          />
          <van-popup
            v-model="InterviewTime"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="date"
              title="选择面试时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="InterviewTime = false"
              @confirm="onInterviewTime"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="mianshi.InterviewPlace"
            label="面试地点"
            placeholder="请输入面试地点"
          />
          <van-field
            v-model="mianshi.Interviewer"
            label="面试官"
            placeholder="请输入面试官"
          />
          <van-radio-group
            v-model="mianshi.IsFinalInterview"
            direction="horizontal"
          >
            <span
              style="
                font-size: 14px;
                color: #646566;
                margin: 15px 55px 15px 20px;
              "
              >终面：</span
            >
            <van-radio name="0">否</van-radio>
            <van-radio name="1">是</van-radio>
          </van-radio-group>
          <van-button
            round
            type="info"
            style="width: 70%; margin: 25px 15%"
            @click="ona()"
            >提 交</van-button
          >
        </div>
      </van-popup>
      <!-- 不合格 -->
      <!-- <van-popup
        v-model="fromindexB"
        round
        position="bottom"
        get-container="body"
        :style="{ height: '30%' }"
      >
        <div>
          <van-field
            v-model="buhege.Reason"
            label="不合格原因"
            type="textarea"
            placeholder="请输入不合格原因"
          />
          <van-button round type="info" style="width: 70%; margin: 25px 15%"
            >提 交</van-button
          >
        </div>
      </van-popup> -->
      <!-- 录用 -->
      <van-popup
        v-model="fromindexC"
        round
        position="bottom"
        get-container="body"
        :style="{ height: '50%' }"
      >
        <div>
          <van-field v-model="luyong.MonthSalary" type="digit" label="月薪" />
          <van-field
            v-model="luyong.OfferDate"
            label="签订时间"
            @click="luyongOfferDate = true"
            readonly="readonly"
            placeholder="请选择签订时间"
          />
          <van-popup
            v-model="luyongOfferDate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="date"
              title="选择签订时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="luyongOfferDate = false"
              @confirm="onluyongOfferDate"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="luyong.PlanInDate"
            label="预计入职日期"
            @click="luyongPlanInDate = true"
            readonly="readonly"
            placeholder="请选择预计入职日期"
          />
          <van-popup
            v-model="luyongPlanInDate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="date"
              title="选择预计入职日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="luyongPlanInDate = false"
              @confirm="onluyongPlanInDate"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="luyong.PlanLeaveDate"
            label="预计离职日期"
            @click="luyongPlanLeaveDate = true"
            readonly="readonly"
            placeholder="请选择预计离职日期"
          />
          <van-popup
            v-model="luyongPlanLeaveDate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="date"
              title="选择预计离职日期"
              :min-date="minDate"
              :max-date="maxDates"
              @cancel="luyongPlanLeaveDate = false"
              @confirm="onluyongPlanLeaveDate"
            >
            </van-datetime-picker>
          </van-popup>
          <van-button round type="info" @click="onb()" style="width: 70%; margin: 25px 15%"
            >提 交</van-button
          >
        </div>
      </van-popup>
      <!-- 入职 -->
      <van-popup
        v-model="fromindexD"
        round
        position="bottom"
        get-container="body"
        :style="{ height: '50%' }"
      >
        <div>
          <van-field
            v-model="ruzhi.EntryRemark"
            label="入职说明"
            type="textarea"
            placeholder="请输入入职说明"
          />
          <van-field
            v-model="ruzhi.InDate"
            label="入职日期"
            @click="ruzhiInDate = true"
            readonly="readonly"
            placeholder="请选择入职日期"
          />
          <van-popup
            v-model="ruzhiInDate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="date"
              title="选择入职日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="ruzhiInDate = false"
              @confirm="onruzhiInDate"
            >
            </van-datetime-picker>
          </van-popup>
          <van-field
            v-model="ruzhi.TryDate"
            label="试用日期"
            @click="ruzhiTryDate = true"
            readonly="readonly"
            placeholder="请选择试用日期"
          />
          <van-popup
            v-model="ruzhiTryDate"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="date"
              title="选择试用日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="ruzhiTryDate = false"
              @confirm="onruzhiTryDate"
            >
            </van-datetime-picker>
          </van-popup>
          <van-button round type="info" @click="onc()" style="width: 70%; margin: 25px 15%"
            >提 交</van-button
          >
        </div>
      </van-popup>
      <!-- 延期入职 -->
      <van-popup
        v-model="fromindexE"
        round
        position="bottom"
        get-container="body"
        :style="{ height: '50%' }"
      >
        <div>
          <van-field
            v-model="yanqi.DelayReason"
            label="延期原因"
            type="textarea"
            placeholder="请输入延期原因"
          />
          <van-field
            v-model="yanqi.DelayDate"
            label="延迟入职日期"
            @click="DelayDate = true"
            readonly="readonly"
            placeholder="请选择延迟入职日期"
          />
          <van-popup v-model="DelayDate" position="bottom" get-container="body">
            <van-datetime-picker
              show-toolbar
              type="date"
              title="选择延迟入职日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="DelayDate = false"
              @confirm="onDelayDate"
            >
            </van-datetime-picker>
          </van-popup>
          <van-button round type="info" @click="ond()" style="width: 70%; margin: 25px 15%"
            >提 交</van-button
          >
        </div>
      </van-popup>
      <!-- 淘汰 -->
      <van-popup
        v-model="fromindexF"
        round
        position="bottom"
        get-container="body"
        :style="{ height: '45%' }"
      >
        <div>
          <van-field
            v-model="taotai.CandidateFB"
            label="候选人反馈"
            placeholder="请输入候选人反馈"
          />
          <van-field
            v-model="taotai.CustomerFB"
            label="企业反馈"
            placeholder="请输入企业反馈"
          />
          <van-radio-group v-model="taotai.EliReason" direction="horizontal">
            <span
              style="
                font-size: 14px;
                color: #646566;
                margin: 15px 20px 15px 20px;
              "
              >不合适原因：</span
            >
            <van-radio name="1">客户放弃</van-radio>
            <van-radio name="2">候选人放弃</van-radio>
          </van-radio-group>
          <van-button
            round
            type="info"
            style="width: 70%; margin: 25px 15%"
            @click="one()"
            >提 交</van-button
          >
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
Vue.use(Dialog);
Vue.use(Toast);
import {
  RE_GetCoResumePage,
  RE_GetRecruitStageList,
  DelayEntry,
  Entry,
  SetUnqualified,
  RCInterview,
  Eliminate,
  Admission,
} from "@/api/REplat";
import { getaccToken, setaccToken, removeaccToken } from "@/utils/auth";
export default {
  data() {
    return {
      listfrom: {},
      listfroms: {
        page: 1,
        limit: 10,
        stage: 1,
      },
      namelist: [],
      StageList: [],
      xiangqing: false,
      detailPage: false,
      fromindexA: false,
      fromindexB: false,
      fromindexC: false,
      fromindexD: false,
      minDate: new Date(1970, 0),
      maxDate: new Date(),
      maxDates: new Date(2030,0),
      luyongOfferDate: false,
      luyongPlanLeaveDate: false,
      luyongPlanInDate: false,
      ruzhiInDate: false,
      ruzhiTryDate: false,
      InterviewTime: false,
      DelayDate: false,
      fromindexE: false,
      fromindexF: false,
      // 流程传参
      mianshi: {
        //面试
        RPId: "", //候选人Id、
        InterviewTime: "", //面试时间、
        InterviewPlace: "", //面试地点、
        Interviewer: "", //面试官、
        IsFinalInterview: "", //终面（0-否、1-是）
      },
      buhege: {
        // 不合格
        RPId: "", //候选人Id、
        Reason: "",
      },
      taotai: {
        //淘汰
        RPId: "", //候选人Id、
        EliReason: "", //淘汰原因（1客户淘汰、2候选人放弃）、
        EliDesc: "", //淘汰或放弃原因（淘汰类别：206、候选人放弃类别：207）
        CandidateFB: "", //候选人反馈、
        CustomerFB: "", //企业反馈
      },
      luyong: {
        //录用
        RPId: "", //候选人Id、
        SalaryStructure: "", //薪资结构、
        MonthSalary: 0, //月薪、
        YearSalary: 0, //年薪、
        OfferDate: "", //Offer签订日期、
        PlanInDate: "", //预计入职日期、
        PlanLeaveDate: "", //预计离职日期
      },
      ruzhi: {
        //入职
        RPId: "", //候选人Id、
        InDate: "", //入职日期、
        TryDate: "", //试用日期、
        EntryRemark: "", //入职说明
      },
      yanqi: {
        //延期入职
        RPId: "", //候选人Id、
        DelayDate: "", //延期入职日期、
        DelayReason: "", //延期原因
      },
    };
  },
  created() {
    this.GetCoResumePage();
    this.GetCoResumePages();
  },
  methods: {
    // 获取流程
    GetCoResumePage: function () {
      this.listfrom.accToken = getaccToken();
      RE_GetRecruitStageList(this.listfrom).then((res) => {
        if (res.data.code == 0) {
          console.log(res);
          this.StageList = res.data.data;
        } else {
        }
      });
    },
    // 获取列表
    GetCoResumePages: function () {
      this.listfroms.accToken = getaccToken();
      RE_GetCoResumePage(this.listfroms).then((res) => {
        if (res.data.code == 0) {
          console.log(res);
          this.namelist = res.data.data;
          console.log(this.namelist);
        } else {
        }
      });
    },
    // 面试
    addRCAdviser(row) {
      Dialog.confirm({
        title: "提示",
        message: "是否加入面试!",
      })
        .then(() => {
          this.mianshi.accToken = getaccToken();
          this.mianshi.RPId = row;
          this.fromindexA = true;
        })
        .catch(() => {
          return false;
        });
    },
    ona() {
      RCInterview(this.mianshi).then((res) => {
        if (res.data.code == 0) {
          Toast.success("提交成功");
          this.fromindexA = false;
        } else {
          Toast.fail("提交失败!" + res.data.msg);
        }
      });
    },
    // 不合格
    RemoveCallList(row) {
      // this.buhege.accToken = getaccToken();
      // this.buhege.RPId = row;
      // this.fromindexB = true;
      // SetUnqualified(this.buhege).then((res) => {
      //   if (res.data.code == 0) {
      //     Toast.success("提交成功");
      //   } else {
      //     Toast.fail("提交失败!" + res.data.msg);
      //   }
      // });
    },
    // 淘汰
    dleEliminate(row) {
      Dialog.confirm({
        title: "提示",
        message: "是否淘汰求职者!!",
      })
        .then(() => {
          this.taotai.accToken = getaccToken();
          this.taotai.RPId = row;
          this.fromindexF = true
        })
        .catch(() => {
          return false;
        });
    },
    one(){
      Eliminate(this.taotai).then((res) => {
            if (res.data.code == 0) {
              Toast.success("提交成功");
              this.fromindexF = false
            } else {
              Toast.fail("提交失败!" + res.data.msg);
            }
          });
    },
    // 录用
    Addmission(row) {
      Dialog.confirm({
        title: "提示",
        message: "是否录用求职者",
      })
        .then(() => {
          this.luyong.accToken = getaccToken();
          this.luyong.RPId = row;
          this.fromindexC = true;
        })
        .catch(() => {
          return false;
        });
    },
    onb(){
      Admission(this.luyong).then((res) => {
            if (res.data.code == 0) {
              Toast.success("提交成功");
              this.fromindexC = false;
            } else {
              Toast.fail("提交失败!" + res.data.msg);
            }
          });
    },
    // 入职
    AddEntry(row) {
      Dialog.confirm({
        title: "提示",
        message: "是否将求职者入职",
      })
        .then(() => {
          this.ruzhi.accToken = getaccToken();
          this.ruzhi.RPId = row;
          this.fromindexD = true;
          this.fromindexD = true;
          
        })
        .catch(() => {
          return false;
        });
    },
    onc(){
      Entry(this.ruzhi).then((res) => {
            if (res.data.code == 0) {
              Toast.success("提交成功");
              this.fromindexD = false;
            } else {
              Toast.fail("提交失败!" + res.data.msg);
            }
          });
    },
    //延期入职
    DelayEntrys() {
      Dialog.confirm({
        title: "提示",
        message: "是否将求职者延期入职",
      })
        .then(() => {
          this.yanqi.accToken = getaccToken();
          this.yanqi.RPId = row;
          this.fromindexE = true; 
        })
        .catch(() => {
          return false;
        });
    },
    ond(){
      DelayEntry(this.yanqi).then((res) => {
            if (res.data.code == 0) {
              Toast.success("提交成功");
              this.fromindexE = false;
            } else {
              Toast.fail("提交失败!" + res.data.msg);
            }
          });
    },
    // tab点击
    onClick(name, title) {
      this.listfroms.stage = name;
      console.log(this.listfroms.stage);
      this.GetCoResumePages();
      // console.log(title);
    },
    onluyongOfferDate(val) {
      //签订时间
      this.luyong.OfferDate = this.dateformat(val);
      this.luyongOfferDate = false;
    },
    onluyongPlanInDate(val) {
      //预计入职日期
      this.luyong.PlanInDate = this.dateformat(val);
      this.luyongPlanInDate = false;
    },
    onluyongPlanLeaveDate(val) {
      //预计离职日期
      this.luyong.PlanLeaveDate = this.dateformat(val);
      this.luyongPlanLeaveDate = false;
    },
    onruzhiInDate(val) {
      //入职日期
      this.ruzhi.InDate = this.dateformat(val);
      this.ruzhiInDate = false;
    },
    onruzhiTryDate(val) {
      //试用日期
      this.ruzhi.TryDate = this.dateformat(val);
      this.ruzhiTryDate = false;
    },
    onInterviewTime(val) {
      //面试日期
      this.mianshi.InterviewTime = this.dateformat(val);
      this.InterviewTime = false;
    },
    onDelayDate(val) {
      //延期入职日期
      this.yanqi.DelayDate = this.dateformat(val);
      this.DelayDate = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.candidates {
  background: rgb(245, 243, 243);
  height: 100%;
}
.candidates .box {
  background: #fff;
  width: 90%;
  margin: 15px 5%;
  border-radius: 15px;
}
</style>